<template>
  <div>
    <div class="breadcrumbOutside">
      <div class="breadcrumbBox">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>冷链车辆</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="mainBox">
      <div class="goodsType_cold">
        <el-row class="goodsType_row" v-for="(item,index) in menulist" :key="index">
          <el-col :span="3" class="goodsType_rowLeft">价格筛选</el-col>
          <el-col :span="1">
            <span
              @click="searchType(0, 1, item.id)"
              :class="{'chooseType':clickedFirst == item.id}"
              :ref="item.id"
            >不限</span>
          </el-col>
          <el-col :span="18">
            <div class="goodsType_rowRight" :ref="index">
              <span
                v-for="(itemc,indexc) in item.childNode"
                :key="indexc"
                @click="searchType(itemc.id, 1, itemc.name)"
                :class="{'chooseType':clickedFirst == itemc.name}"
                :ref="itemc.name"
              >{{itemc.name}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row class="goodsType_rows">
          <el-col :span="3" class="goodsType_rowLeft">选择区域</el-col>
          <el-col :span="1">
            <span
              @click="searchType('0', 2, '1')"
              :class="{'chooseType':clickedSecond == '1'}"
              :ref="1"
            >不限</span>
          </el-col>
          <el-col :span="18">
            <el-select v-model="provice" @change="choseProvince" placeholder="选择省">
              <el-option

                v-for="item in proviceList"
                :key="item.name"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="city" style="margin-left: 20px;" @change="choseCity"  placeholder="选择市">
              <el-option
                v-for="item in cityList"
                :key="item.name"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="district" style="margin-left: 20px;" @change="choseDistrict"  placeholder="选择区">
              <el-option
                v-for="item in districtList"
                :key="item.name"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
      <div class="goodsMain">
        <div class="goodsMainleft">
          <div class="gml_top">
            <div
              class="gml_top_block"
              v-for="(item,index) in hotmenulist"
              :key="index"
              @click="searchType(item.id,3,item.name)"
              :class="{'chooseType':clickedThird==item.name}"
            >{{item.name}}</div>
          </div>
          <div class="gml_bottom">
            <div class="goodsBlock" v-for="(item,index) in pagelist" :key="index">
              <div class="goodsBlockInside" @click="toDealDetail(item.id)">
                <div class="marketImgBox">
                  <img :src="item.imgs[0]" alt />
                </div>
                <div class="marketfont">
                  <div class="market_Price" v-if="(item.price != null)">¥{{item.price}}</div>
                  <div class="market_Price" v-else>价格面议</div>
                  <div class="market_account">{{item.title}}</div>
                  <div class="market_place">发货地：{{item.province}}</div>
                  <div class="market_seller">{{item.name}}</div>
                </div>
              </div>
            </div>
            <div class="nogoods" v-show="showTips">
              <img src="../../../public/static/img/market/暂无分类@3x.png" />
              当前分类暂无商品
            </div>
          </div>
          <div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="datalength"
              :page-size="pageSize"
              :current-page.sync="pageNum"
              @current-change="paginationChange"
            ></el-pagination>
          </div>
        </div>
        <div class="goodsMainright">
          <div class="gmr_top">精选推荐<a class="rightSpan" @click="recommendListBtn">更多</a></div>
          <div>
            <div class="recommendGoods" v-for="(item,index) in randomlist" :key="index">
              <div class="goodsBlockInside" @click="toDealDetail(item.id)">
                <div class="marketImgBox">
                  <img :src="item.imgs[0]" alt />
                </div>
                <div class="marketfont">
                  <div class="market_Price" v-if="(item.price != null)">¥{{item.price}}</div>
                  <div class="market_Price" v-else>价格面议</div>
                  <div class="market_account">{{item.title}}</div>
                  <div class="market_place">发货地：{{item.province}}</div>
                  <div class="market_seller">{{item.name}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../../../src/pages/market/market.css";
export default {
  data() {
    return {
      startTime: null,
      pageName: "冷链车辆(coldChainCar.vue)",
      primaryMenuId: this.$route.query.id,
      productTypeId: 0, // 价格排序 0:不限 1:小于1000 2:1000~5000 3:5001~10000 4:10000以上
      transferType: 1, // 交易类型 1:出租 2:求租
      secondaryMenuId: "",
      menulist: [
        {
          name: '价格筛选',
          id: '1',
          childNode: [
            {
              name: '小于1000',
              id: '1'
            },
            {
              name: '1000 - 5000',
              id: '2'
            },
            {
              name: '5001 - 10000',
              id: '3'
            },
            {
              name: '10000以上',
              id: '4'
            }
          ]
        }
      ],
      hotmenulist: [
        {
          name: '冷链出租',
          id: 1
        },
        {
          name: '冷链求租',
          id: 2
        }
      ],
      provice: '', // 选中省
      city: '', // 选中市
      district: '', // 选中区
      proviceList: [], // 省列表
      cityList: [], // 市列表
      districtList: [], // 市列表
      val: [],
      pagelist: [],
      randomlist: [],
      datalength: 0,
      pageNum: 1,
      pageSize: 12,
      loading: false,
      showTips: false,
      clickedFirst: '',
      clickedSecond: '',
      clickedThird: '冷链出租'
    };
  },
  beforeCreate() {
    this.startTime = performance.now();
    let this_ = this;
    //1.页面停留时间
    window.addEventListener('beforeunload', function() {
      let endTime = performance.now();
      let stayTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("stayTime", stayTime);

      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          stayTime: stayTime,
        }
      })
    });
    //3.页面加载时间
    window.addEventListener('load', function() {
      let endTime = performance.now();
      let loadTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("loadTime", loadTime);
      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          loadTime: loadTime,
        }
      })
    });
  },
  created() {
    //2.用户浏览量
    window.SZHN_FIRE_BEAR?.trackEvent({
      key: this.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
      segmentation: {
        pageViews: 1,
      }
    })
    //4.网络请求状态
    this.trackNetworkRequest("https://nfggy.com");
  },
  mounted() {
    this.marketRandomList();
    this.marketPageList();
    this.getCityInfo('PROVINCE', '', false);
  },
  methods: {
    trackNetworkRequest(url) {
      let requestStartTime = performance.now();
      let this_ = this;

      fetch(url)
          .then(response => {
            let requestEndTime = performance.now();
            let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
            console.log("requestSuccessTime", requestTime);
            window.SZHN_FIRE_BEAR?.trackEvent({
              key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
              segmentation: {
                requestTime: requestTime,
                requestStatu: "success",
                requestResult: response.toString()
              }
            })
          })
          .catch(error=>{
            let requestEndTime = performance.now();
            let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
            console.log("requestErrorTime", requestTime);

            window.SZHN_FIRE_BEAR?.trackEvent({
              key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
              segmentation: {
                requestTime: requestTime,
                requestStatu: "error",
                requestResult: error.toString()
              }
            })
          })
    },
    /** 获取市区村地址 */
    async getCityInfo(level, id, isEchoDisplay) {
      const api = this.$fetchApi.getCityList.api;
      const data = await this.$fetchData.fetchPost({ level, parentId: id }, api, 'json');
      if (data.code === '200') {
        if (level === 'PROVINCE') { // 省
          this.proviceList = data.result;
        }  else
        if (level === 'CITY') { // 市
          this.cityList = data.result;
        } else
        if (level === 'DISTRICT') { // 区
          this.districtList = data.result;
        }
      } else {
        // Message({
        //   showClose: true,
        //   message: data.message,
        //   type: 'error'
        // });
      }
    },
     // 选择省
    async choseProvince(e) {
      await this.getCityInfo('CITY', e, true);
      this.provice = e;
      this.city = '';
      this.district = '';
      this.clickedSecond = '';
      this.marketPageList();
    },
    // 选择市
    async choseCity(e) {
      await this.getCityInfo('DISTRICT', e, true);
      this.city = e;
      this.district = '';
      this.clickedSecond = '';
      this.marketPageList();
    },
    // 选择区
    async choseDistrict(e) {
      this.district = e;
      this.clickedSecond = '';
      this.marketPageList();
    },
    // 跳转详情
    toDealDetail(id) {
      this.$router.push({
        path: "/coldChainCarDetails",
        query: {
          id
        }
      });
    },
    // 跳转精选推荐
    recommendListBtn() {
      this.$router.push({
        path: "/recommendList"
      });
    },
    // 页数按钮
    paginationChange(val) {
      this.pageNum = val;
      this.marketPageList();
    },
    // 精品推荐
    async marketRandomList() {
      const api = this.$fetchApi.coldChainCarList.api;
      const data = await this.$fetchData.fetchPost(
        {
          city: '', // 市
          district: '', // 区
          pageNum: 1,
          pageSize: 3,
          priceOrder: 0, // 价格排序 0:不限 1:小于1000 2:1000~5000 3:5001~10000 4:10000以上
          province: '', // 省
          recommend: true, // 推荐
          transferType: 0 // 交易类型 1:出租 2:求租
        },
        api,
        "json"
      );
      if (data.code === "200" && data.result) {
        this.randomlist = data.result.rows;
      }
    },
    // 获取全部列表
    async marketPageList() {
      const params =
        {
        city: this.city, // 市
        district: this.district, // 区
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        priceOrder: this.productTypeId, // 价格排序 0:不限 1:小于1000 2:1000~5000 3:5001~10000 4:10000以上
        province: this.provice, // 省
        // recommend: false, // 推荐
        transferType: this.transferType // 交易类型 1:出租 2:求租
        };
      const api = this.$fetchApi.coldChainCarList.api;
      const data = await this.$fetchData.fetchPost(params, api, "json");
      if (data.code === "200" && data.result) {
        this.datalength = data.result.total;
        this.pagelist = data.result.rows;
        if (this.pagelist.length === 0) {
          this.showTips = true;
        } else {
          this.showTips = false;
        }
      }
    },
    searchType(id, num, name) {
      if (num === 1) {
        this.clickedFirst = name;
        this.productTypeId = id;
        this.pageNum = 1;
        this.marketPageList();
      }
      if (num === 2) {
        this.clickedSecond = name;
        this.pageNum = 1;
        this.secondaryMenuId = "";
        this.provice = '';
        this.city = '';
        this.cityList = [];
        this.district = '';
        this.districtList = [];
        this.marketPageList();
      }
      if (num === 3) {
        this.clickedThird = name;
        this.transferType = id;
        this.pageNum = 1;
        this.marketPageList();
      }
    }
  }
};
</script>
<style scoped>
</style>
